import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
// UI
import ButtonComponent from 'ui-kit-v2/button/button';
import { SpinnerV2 } from 'ui-kit-v2/spinner/spinner';

// Components
import BalanceByMemberList from 'display-components/balance-by-member-list';
import { parsePatientName } from 'display-components/balance-by-member-list/balance-by-member-list.helpers';
import MakePayment from 'display-components/payment-history/make-payment-modal/make-payment-modal.component';
import TransactionList from 'display-components/payment-history/transaction-list';
import UserBalance from 'display-components/payment-history/user-balance';

import ProfileLayout from 'components/layouts/profile/profile.layout';
import { TransactionCardVariant } from 'components/transaction-card/transaction-card.types';

import { accountFetchProfileRoutine } from 'state/account/account.routines';
// States
import { accountProfileSelector, accountStateSelector } from 'state/account/account.selectors';
import { ProfileObjectPayload } from 'state/account/account.services';
import { familyProfileDependentsSelector } from 'state/family-profile/family-profile.selectors';
import { closeModalComponent, openModalComponent } from 'state/modal/modal.reducer';
import { paymentsV2GetPaymentHistoryRoutine } from 'state/payments/payments.routines';
import { paymentIsLoadingHistorySelector, paymentsStateSelector } from 'state/payments/payments.selectors';

// Types
import { PaymentHistoryV2Result } from 'types/payment-history';

import { ApiStatus } from 'enums/api-status';

// Utils
import { noop, parseObject } from 'util/function';
import { convertToTitleCase } from 'util/string';

import './payment-history.style.scss';

const PaymentHistory = () => {
    // Hooks
    const dispatch = useDispatch();
    const { t } = useTranslation();

    // Selectors
    const { paymentHistoryV2 } = useSelector(paymentsStateSelector);
    const isLoadingPaymentHistoryData = useSelector(paymentIsLoadingHistorySelector);
    const familyProfileData = useSelector(familyProfileDependentsSelector);
    const profileObject = useSelector(accountProfileSelector);
    const { profileApiStatus } = useSelector(accountStateSelector);

    // Local State
    const [activeTab, setActiveTab] = useState(profileObject?.epostPatientNum || '');
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize] = useState(10);
    const [disabledPagination, setDisabledPagination] = useState(false);
    const [showPagination, setShowPagination] = useState(false);
    const [isShowAll, setIsShowAll] = useState(false);
    const [paymentsList, setPaymentsList] = useState<PaymentHistoryV2Result[]>([]);

    /*****************************
    Transactions Result Section */

    const handleChangeTab = (epostPatientNum: string) => {
        // don't load again if I click in the same tab I'm already in
        if (epostPatientNum !== activeTab) {
            setCurrentPage(1);
            setActiveTab(epostPatientNum);
            setPaymentsList([]);
        }
    };

    const fetchPaymentsHistory = useCallback(
        (epostPatientNum: string, pageSize = 10, currentPage = 1, onSuccess?: () => void) => {
            dispatch(
                paymentsV2GetPaymentHistoryRoutine.trigger({
                    page: currentPage.toString(),
                    pageSize: pageSize.toString(),
                    includeAging: 'false',
                    epostPatientNum,
                    onSuccess: () => {
                        onSuccess?.();
                    }
                })
            );
        },
        [dispatch, currentPage, pageSize]
    );

    const hasRecords = useMemo(() => {
        if (paymentHistoryV2?.totalRecords === 0) return false;

        return true;
    }, [paymentHistoryV2]);

    const parsePaymentHistoryV2Result = (data: Record<string, any>) => {
        const targetObject = {
            transactionInfo: {
                DMEItem: '',
                DMEOrder: '',
                EPostScriptId: '',
                GLDebitCredit: '',
                GLPaymentNumber: '',
                GLPostAmount: '',
                GLPostDatetime: '',
                GLPostNote: '',
                GLPostStatusDesc: '',
                GLPostStatusNum: '',
                GLPostType: '',
                GLPostUser: '',
                epostPatientNum: '',
                familyId: '',
                orderPaymentCardMonth: '',
                orderPaymentCardMonthNum: '',
                orderPaymentCardNumber: '',
                orderPaymentCardSeqNum: '',
                orderPaymentCardTypeDesc: '',
                orderPaymentCardTypeNum: '',
                orderPaymentCardYear: '',
                patientName: '',
                displayDateTime: '',
                displayDate: '',
                paidUsingCredit: '',
                accountsReceivableType: '',
                accountsReceivableStatus: ''
            },
            user: '',
            userType: ''
        };
        const parsedData = parseObject(targetObject, data);

        const isTransactionFromDependent = familyProfileData.find(
            (dep) => dep.ePostPatientNum === data.epostPatientNum
        );
        const isTransactionFromCurrentUser = data.epostPatientNum === profileObject?.epostPatientNum;

        // should replicate BE name convention - "LASTNAME,FISRTNAME"
        const patientName = isTransactionFromCurrentUser
            ? `${profileObject?.patientLastName},${profileObject?.patientFirstName}`
            : `${isTransactionFromDependent?.familyMemberLastName},${isTransactionFromDependent?.familyMemberFirstName}`;

        const isSoloUser = !profileObject?.isCaregiver && familyProfileData.length === 0;

        return {
            ...parsedData,
            user: isSoloUser ? '' : parsePatientName(patientName, 'capitalize'),
            userType: !isTransactionFromDependent ? 'caregiver' : 'dependent'
        };
    };

    /*********************
    Pagination Section */

    const resultsAmount = useMemo(() => {
        if (!paymentHistoryV2) return 0;

        const totalRecords = paymentHistoryV2.totalRecords || 0;
        const loadedRecords = paymentsList.length;
        const remainingRecords = totalRecords - loadedRecords;

        const shouldDisablePagination = remainingRecords <= 0;
        setDisabledPagination(shouldDisablePagination);
        setShowPagination(totalRecords > pageSize);

        return shouldDisablePagination ? 0 : Math.min(pageSize, remainingRecords);
    }, [paymentsList, paymentHistoryV2, pageSize]);

    const handleShowMore = () => {
        setCurrentPage(currentPage + 1);
    };

    const handleShowAll = () => {
        fetchPaymentsHistory(activeTab, paymentHistoryV2?.totalRecords);
        setIsShowAll(true);
    };

    useEffect(() => {
        if (profileObject) handleChangeTab(profileObject?.epostPatientNum || '');
    }, [profileObject]);

    useEffect(() => {
        if (activeTab) {
            fetchPaymentsHistory(activeTab, pageSize, currentPage);
        }
    }, [activeTab, currentPage]);

    useEffect(() => {
        // this will be removed once we start to use getDependents again
        // today we are using getProfile which already returns the dependents because when dependents accept the invitation is not reflecting in payment-history
        dispatch(accountFetchProfileRoutine.trigger());
    }, [dispatch]);

    /**********************
    User Balance Section */

    const creditBalance = useMemo(() => {
        if (!paymentHistoryV2) return undefined;

        return paymentHistoryV2.currentBalance;
    }, [paymentHistoryV2]);

    const debitFamilyBalance = useMemo(() => {
        const dependents = profileObject?.dependents ?? [];
        const familyMembers = [profileObject, ...dependents];

        return familyMembers.reduce((acc, dep) => {
            const patientBalance = dep?.patientBalance;
            const balance = patientBalance ? parseFloat((patientBalance as string).replace(/,/g, '')) : NaN;
            if (!isNaN(balance) && balance > 0) {
                acc += balance;
            }
            return acc;
        }, 0);
    }, [profileObject]);

    const getBalanceByMemberData = useCallback(() => {
        if (!profileObject) return [];

        const dependents =
            profileObject.dependents?.map((patient: Partial<ProfileObjectPayload>) => {
                return {
                    epostPatientNum: patient.epostPatientNum,
                    // Ensures we are correctly treating the amount string for cases like "11,000.00"
                    currentBalance: parseFloat((patient.patientBalance as string).replace(/,/g, '')),
                    patientName: convertToTitleCase(`${patient.patientFirstName} ${patient.patientLastName}`),
                    isCaregiver: false
                };
            }) || [];

        return [
            // Caregiver info, goes first to be on top of the list
            {
                epostPatientNum: profileObject.epostPatientNum,
                currentBalance: parseFloat((profileObject.patientBalance as string).replace(/,/g, '')),
                patientName: convertToTitleCase(`${profileObject.patientFirstName} ${profileObject.patientLastName}`),
                isCaregiver: true
            },
            ...dependents
        ].filter((member) => {
            const balance = parseFloat(String(member.currentBalance));
            return !isNaN(balance) && balance !== 0;
        });
    }, [familyProfileData, profileObject]);

    const getUserBalance = useCallback(() => {
        if (debitFamilyBalance && debitFamilyBalance > 0) {
            return {
                balanceValue: debitFamilyBalance,
                onClickHandler: handleMakePaymentFullBalanceModal
            };
        } else if (!profileObject?.isCaregiver && creditBalance && creditBalance < 0) {
            return {
                balanceValue: creditBalance,
                onClickHandler: noop
            };
        }
        return null;
    }, [debitFamilyBalance, creditBalance, profileObject]);

    /*******************
    Payments Section */

    /* This comparison determines whether the `isTotalPayment` flag should be set to `true` or `false`.
    The flag is `true` only when:
    - The user is a Caregiver (`profileObject?.isCaregiver` is `true`).
    - The amount to pay includes contributions from both the owner and dependents, meaning the
    current balance (creditBalance) is less than the total family balance (debitFamilyBalance).

    If the current balance is equal to the total balance (`debitFamilyBalance === creditBalance`),
    it means the payment applies only to the owner, not the family group. In this case, the flag
    must be `false` to prevent treating an individual payment as a family payment, which could
    cause bugs in the API. */

    const isTotalPayment = useMemo(() => {
        const isTotalBalanceEqualToCurrentBalance = debitFamilyBalance === creditBalance;

        if (profileObject?.isCaregiver && !isTotalBalanceEqualToCurrentBalance) {
            return true;
        } else {
            return false;
        }
    }, [profileObject, paymentHistoryV2]);

    const handleMakePaymentByMemberModal = (patientName: string, amount: number, epostPatientNum: string) => {
        dispatch(
            openModalComponent({
                hasDefaultFooter: false,
                hasCustomContent: true,
                hasModalHeader: false,
                hasCustomHeader: true,
                isCloseable: false,
                content: (
                    <MakePayment
                        paymentInformation={{
                            isCaregiver: false,
                            dependentName: patientName,
                            amountDue: amount,
                            epostPatientNum
                        }}
                        fetchPaymentData={() => {
                            if (activeTab === profileObject?.epostPatientNum) {
                                setPaymentsList([]);
                                fetchPaymentsHistory(profileObject?.epostPatientNum);
                            } else {
                                handleChangeTab(profileObject?.epostPatientNum as string);
                            }
                        }}
                    />
                ),
                variation: 'small',
                isCentered: true,
                backdrop: 'static',
                onClose: () => {
                    dispatch(closeModalComponent());
                }
            })
        );
    };

    const handleMakePaymentFullBalanceModal = (isCaregiver?: boolean) => {
        dispatch(
            openModalComponent({
                hasDefaultFooter: false,
                hasCustomContent: true,
                hasModalHeader: false,
                hasCustomHeader: true,
                isCloseable: false,
                content: (
                    <MakePayment
                        paymentInformation={{
                            isCaregiver: isCaregiver ? profileObject?.isCaregiver : false,
                            amountDue: debitFamilyBalance as number,
                            epostPatientNum: profileObject?.epostPatientNum as string,
                            isTotalPayment: isTotalPayment
                        }}
                        fetchPaymentData={() => {
                            if (activeTab === profileObject?.epostPatientNum) {
                                setPaymentsList([]);
                                fetchPaymentsHistory(profileObject?.epostPatientNum);
                            } else {
                                handleChangeTab(profileObject?.epostPatientNum as string);
                            }
                        }}
                    />
                ),
                variation: 'small',
                isCentered: true,
                backdrop: 'static',
                onClose: () => {
                    dispatch(closeModalComponent());
                }
            })
        );
    };

    const mergePaymentResults = (existingList: PaymentHistoryV2Result[], newList: PaymentHistoryV2Result[]) => {
        const mergedList = [...existingList, ...newList];

        // Sort by GLPostDatetime in descending order
        mergedList.sort((a, b) => new Date(b.GLPostDatetime).getTime() - new Date(a.GLPostDatetime).getTime());

        if (paymentHistoryV2?.totalRecords !== undefined) {
            setDisabledPagination(mergedList.length >= paymentHistoryV2.totalRecords);
        }

        return mergedList;
    };

    useEffect(() => {
        if (paymentHistoryV2) {
            const newPayments = paymentHistoryV2.results || [];
            setPaymentsList((prevPayments) => {
                // Check if show all button was clicked and only append new results
                if (isShowAll) {
                    const newItems = newPayments.slice(prevPayments.length);
                    setIsShowAll(false);
                    return mergePaymentResults(newItems, prevPayments);
                }
                // Don't add identical results
                // Enhancement: BE needs to give us an id or other unique identifier
                // so we can filter results instead of doing this.
                if (
                    prevPayments.length > 0 &&
                    prevPayments.every(
                        (payment, index) => JSON.stringify(payment) === JSON.stringify(newPayments[index])
                    )
                ) {
                    return prevPayments;
                }

                return mergePaymentResults(newPayments, prevPayments);
            });

            setDisabledPagination(paymentHistoryV2.totalRecords <= paymentsList.length);
            setShowPagination(paymentHistoryV2.totalRecords > pageSize);
        }
    }, [paymentHistoryV2]);

    /*******************
    All Sections */

    const sections = useMemo(() => {
        const loaderRender = (payment = false) => (
            <div
                className={`${
                    payment ? 'payment-history-container__loading--payment' : 'payment-history-container__loading'
                }`}
            >
                <SpinnerV2 />
            </div>
        );

        const balanceByMembersData = getBalanceByMemberData();
        const membersWithBalance = balanceByMembersData.filter((member) => member.currentBalance !== 0);

        const balanceByMember = {
            heading: t('pages.profile.balanceByMember.eyebrowText'),
            children: (
                <div className="balance-container">
                    {profileApiStatus === ApiStatus.LOADING ? (
                        loaderRender()
                    ) : (
                        <BalanceByMemberList
                            onClickPay={handleMakePaymentByMemberModal}
                            members={balanceByMembersData}
                        />
                    )}
                </div>
            ),
            suppressChildrenContainer: true
        };

        const paymentHistory = {
            heading: t(`pages.profile.paymentHistory.heading`),
            children: (
                <div className="payment-history-container">
                    {isLoadingPaymentHistoryData && !isShowAll && currentPage <= 1 && loaderRender(true)}

                    {!isLoadingPaymentHistoryData || currentPage >= 1 ? (
                        <TransactionList
                            variant={TransactionCardVariant.PAYMENT}
                            transactions={paymentsList.map((payment) => parsePaymentHistoryV2Result(payment))}
                            activeTab={activeTab}
                            onNavigate={handleChangeTab}
                            isLoading={isLoadingPaymentHistoryData}
                        />
                    ) : undefined}

                    {isLoadingPaymentHistoryData && (currentPage > 1 || isShowAll) && loaderRender()}

                    {showPagination && hasRecords && paymentsList.length >= 10 && !isLoadingPaymentHistoryData && (
                        <Row className="payment-history__pagination">
                            <ButtonComponent
                                className="show-more-button"
                                type="button"
                                variant="link"
                                disabled={disabledPagination}
                                onClick={handleShowMore}
                                label={t('pages.profile.paymentHistory.ctas.showMore', {
                                    amount: resultsAmount,
                                    total: paymentHistoryV2?.totalRecords || 10
                                })}
                            />
                            <p className="payment-history__pagination-divider">|</p>
                            <ButtonComponent
                                disabled={disabledPagination}
                                className="show-more-button"
                                type="button"
                                variant="link"
                                onClick={handleShowAll}
                                label={t('pages.profile.paymentHistory.ctas.showAll')}
                            />
                        </Row>
                    )}
                </div>
            ),
            suppressChildrenContainer: true,
            showMemberTabs: true,
            showEveryone: false,
            activeTab: activeTab,
            onTabItemChange: handleChangeTab
        };

        const paymentHistoryRender = paymentHistory;

        const userBalanceData = getUserBalance();

        const userBalance = {
            heading: undefined,
            children:
                profileApiStatus === ApiStatus.LOADING
                    ? undefined
                    : membersWithBalance.length > 0 && (
                          <UserBalance
                              value={userBalanceData?.balanceValue}
                              handleOnClick={userBalanceData?.onClickHandler}
                          />
                      )
        };

        const showUserBalance = userBalanceData?.balanceValue !== undefined;
        const showBalanceByMember = profileObject?.isCaregiver && membersWithBalance?.length !== 0;

        return [
            ...(showUserBalance ? [userBalance] : []),
            ...(showBalanceByMember ? [balanceByMember] : []),
            paymentHistoryRender
        ];
    }, [
        t,
        activeTab,
        isLoadingPaymentHistoryData,
        paymentHistoryV2,
        familyProfileData,
        profileObject,
        debitFamilyBalance,
        creditBalance,
        getBalanceByMemberData,
        handleMakePaymentByMemberModal
    ]);

    return (
        <ProfileLayout
            eyebrowText={t(`pages.profile.paymentHistory.eyebrowText`)}
            title={t(`pages.profile.paymentHistory.title`)}
            suppressChildrenContainer={true}
            sections={sections}
        />
    );
};

export default PaymentHistory;

export const query = graphql`
    query PaymentHistoryData($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
