import { ThemeColors } from 'types/ui-kit';

import './spinner.styles.scss';

interface SpinnerV2Props extends React.HtmlHTMLAttributes<HTMLDivElement> {
    size?: 'sm' | 'md' | 'lg';
    color?: ThemeColors;
    isVisible?: boolean;
    fullScreen?: boolean;
}

const sizeMap = {
    sm: 32,
    md: 48, // Medium size
    lg: 64 // Large size
};

export const SpinnerV2 = ({ size = 'sm', color = 'cadet-blue', className = '', ...props }: SpinnerV2Props) => {
    const spinnerSize = sizeMap[size] || sizeMap.md;
    const strokeWidth = spinnerSize * 0.125; // Makes stroke proportional
    const radius = (spinnerSize - strokeWidth) / 2;
    const circumference = 2 * Math.PI * radius;
    const strokeDasharray = circumference * 0.7; // Arc length
    const strokeDashoffset = circumference * 0.45; // Gap positioning

    return (
        <div className={`spinner-v2-container ${className}`} {...props}>
            <svg
                width={spinnerSize}
                height={spinnerSize}
                viewBox={`0 0 ${spinnerSize} ${spinnerSize}`}
                className="spinner-v2-component"
            >
                {/* Background Circle */}
                <circle
                    cx={spinnerSize / 2}
                    cy={spinnerSize / 2}
                    r={radius}
                    stroke="rgba(30, 72, 122, 0.2)" //intial color
                    strokeWidth={strokeWidth}
                    fill="none"
                />
                {/* Spinning Arc */}
                <circle
                    cx={spinnerSize / 2}
                    cy={spinnerSize / 2}
                    r={radius}
                    stroke={`var(--color-${color}, #1e487a)`} //intial color
                    strokeWidth={strokeWidth}
                    strokeLinecap="round"
                    fill="none"
                    strokeDasharray={strokeDasharray + ' ' + circumference}
                    strokeDashoffset={strokeDashoffset}
                    transform={`rotate(-90 ${spinnerSize / 2} ${spinnerSize / 2})`}
                >
                    <animateTransform
                        attributeName="transform"
                        type="rotate"
                        from={`0 ${spinnerSize / 2} ${spinnerSize / 2}`}
                        to={`360 ${spinnerSize / 2} ${spinnerSize / 2}`}
                        dur="1s"
                        repeatCount="indefinite"
                    />
                </circle>
            </svg>
        </div>
    );
};
